import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import {getCurrencyString} from "../../../../../../features/formatters/forrmaterrub";

const TkpAdditionalMaterialPdf = ({defaultStyles, material, visiblePrice, tkp}) => {
    return (
        <View style={[defaultStyles.item]}>
            <View style={[defaultStyles.column, {flex: 1}]}>
                <Text style={defaultStyles.names}>
                    {material?.materialType === 'equipment' ? material?.equipment?.name : material?.material === 'component' ? material?.component?.printName : "Не выбран"}
                </Text>
            </View>
            <View style={[defaultStyles.column, {flex: 1}]}>
                <Text style={defaultStyles.tableHeaderText}>{material?.quantity}</Text>
            </View>
            {visiblePrice &&
                <View style={[defaultStyles.column, {flex: 1}]}>
                    <Text style={defaultStyles.tableHeaderText}>
                        {getCurrencyString(material?.materialType === 'equipment' ? material?.equipment?.priceForUnit : material?.material === 'component' ? material?.component?.priceForUnit : 0, tkp?.currencyForHandle?.currencyCode)}
                    </Text>
                </View>
            }
            {/*{visiblePrice &&*/}
            {/*    <View style={[defaultStyles.column, {flex: 1}]}>*/}
            {/*        <Text style={defaultStyles.tableHeaderText}>{material?.discountManager}</Text>*/}
            {/*    </View>*/}
            {/*}*/}
            {visiblePrice &&
                <View style={[defaultStyles.column, {flex: 1}]}>
                    <Text
                        style={defaultStyles.tableHeaderText}>{getCurrencyString(material?.totalPriceIncludingPackaging || 0, tkp?.currencyForHandle?.currencyCode)}</Text>
                </View>
            }
        </View>
    );
};

export default TkpAdditionalMaterialPdf;